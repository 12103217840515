<template>
  <div class="page_d">
    <!-- <div class="text">hint:{{ err }}</div> -->
    <!-- <div id="result">result:{{ result }}</div> -->
    <video ref="video" class="video_d" id="preview" autoplay="" playsinline=""></video>
    <!-- <canvas ref="canvas"  width="300" height="300" style="display: none"></canvas> -->
    <!-- style="display: none;" -->
  </div>

</template>

<script>
let video
let streamP
import JSQR from 'jsqr';
export default {
  name: "video",
  data() {
    return {
      result: '',
      err: ''

    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.result = ''
    this.getnew()
  },
  destroyed() {
    if (streamP) {
      streamP.getTracks()[0].stop();
    }

  },
  methods: {
    getnew() {
      var that = this
      try {
        navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(function (stream) {
          var scanCanvas = document.createElement("canvas");
          scanCanvas.width = 300; // 根据需要设置
          scanCanvas.height = 300; // 根据需要设置
          var scanContext = scanCanvas.getContext('2d');
          var video = document.getElementById("preview");

          video.srcObject = stream;
          streamP = stream
          //  video.setAttribute("playsinline", true); // iOS 使用这个属性
          video.play();
          const scan = setInterval(() => {
            scanContext.drawImage(video, 0, 0, scanCanvas.width, scanCanvas.height);

            var imageData = scanContext.getImageData(0, 0, scanCanvas.width, scanCanvas.height);
            var code = JSQR(imageData.data, imageData.width, imageData.height, {
              inversionAttempts: "dontInvert",
            });
            that.result = code
            console.log(code);
            if (code) {
              clearInterval(scan);
              that.$message.closeAll();
that.$message({
                message: that.$t("message.FoundTheQRCode"),
                customClass: 'custom-message',
                duration: 2000,
                offset: window.screen.height / 2
              });
              that.result = code
              localStorage.setItem("qrcode", code)
              stream.getTracks()[0].stop();
              setTimeout(function () {
                that.$router.go(-1)
              }, 1000)
            } else {
              that.result = '未识别'
            }
          }, 100)

        }).catch(err => {
          console.error("Error accessing media devices.", err);
          that.$message.closeAll();
that.$message({
            message: that.$t("message.PleaseUseYourMobilePhoneToLog"),
            customClass: 'custom-message',
            duration: 2000,
            offset: window.screen.height / 2,
            onClose: function () {
              that.$router.go(-1)
              window.location.reload();
            }
          });
        });
      } catch (error) {
        that.err = error
        that.$message.closeAll();
that.$message({
          message: that.$t("message.PleaseAllowAuthorizedUseOfYourPhone"),
          customClass: 'custom-message',
          duration: 2000,
          offset: window.screen.height / 2,
          onClose: function () {
            that.$router.go(-1)
          }
        });
      }

    },
  },
};
</script>

<style scoped>
.video_d {
  /* box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0; */
  width: 100vw;
  height: 100vh;
}

.text {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  white-space: normal;
  /* 保留空白符序列，但是正常换行。*/
  word-break: break-all;
  /* 允许在单词内部任意位置换行 */
}
</style>