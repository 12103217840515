<template>
  　　　　<svg :class="svgClassName" aria-hidden="true" title=" 11">

    　　　　<use :xlink:href="svgIcon"></use>
    　　　　</svg>
  　　　　</template>

　　　　
<script>
import { ref, computed } from "vue"
export default {
  name: 'SvgIcon',
  props: {
    iconName: {
      type: String,
      default: ""
    },
    className: {
      type: String,
      default: ""
    },
  },
  setup(props) {
    const svgClassName = computed(() => {
      if (props.className) {
        return `svg_class ${props.className}`
      }
      return "svg_class"
    })
    const svgIcon = computed(() => `#icon-${props.iconName}`)
    return { svgClassName, svgIcon }
  }

};
</script>

　　　　
<style lang="scss" scoped>

.svg_class {
  width: 100%;
  height: 100%;
  fill: currentColor;
  overflow: hidden;
  vertical-align: middle;
}
</style>