import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import fly from '@/config/request'
import api from '@/config/api'
import jQuery from 'jquery'


Vue.prototype.$jQuery = jQuery
import ElementUI from 'element-ui';
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'element-ui/lib/theme-chalk/index.css'
import enLocale from 'element-ui/lib/locale/lang/en'
// import '@/static/css/font-awesome.min.css'
// import '@/static/css/owl.carousel.min.css'
// import '@/static/css/jquery-ui.css'
// import '@/static/css/slick.css'
// import '@/static/css/chosen.min.css'
// import '@/static/css/pe-icon-7-stroke.css'
// import '@/static/css/magnific-popup.min.css'
// import '@/static/css/jquery.fancybox.css'
// import '@/static/css/lightbox.min.css'
// import '@/static/css/jquery.scrollbar.min.css'
import '@/static/css/mobile-menu.css'
import '@/static/css/flaticon.css'
import '@/static/css/style.scss'
import 'animate.css'

import i18n from '@/static/js/i18n';

import '@/static/js/jquery-1.12.4.min.js'
// import '@/static/js/jquery-ui.min.js'
// import '@/static/js/magnific-popup.min.js'
// import '@/static/js/isotope.min.js'
// import '@/static/js/slick.min.js'
// import '@/static/js/jquery.mousewheel.min.js'
// import '@/static/js/jquery-countdown.min.js'
// import '@/static/js/jquery.plugin-countdown.min.js'

// import '@/static/js/owl.carousel.min.js'

// import '@/static/js/jquery.scrollbar.min.js'

// import '@/static/js/mobile-menu.min.js'
// import '@/static/js/chosen.min.js'

// import '@/static/js/jquery.elevateZoom.min.js'
// import '@/static/js/jquery.actual.min.js'
// import '@/static/js/jquery.fancybox.js'
// import '@/static/js/lightbox.min.js'
// import '@/static/js/owl.thumbs.min.js'
// import '@/static/js/frontend-plugin.js'
import { clearLocalStorage, isValidEmail, checkMobile, mySetCookie, checkPhone } from '@/static/js/common.js'

Vue.prototype.$layer = layer(Vue, {
  msgtime: 3 //目前只有一项，即msg方法的默认消失时间，单位：秒
})
Vue.config.productionTip = false;
console.log("*****fly", fly, "**********");
Vue.prototype.$flyNo = fly
Vue.prototype.httpUrl = api.api;
Vue.prototype.clearLocalStorage = clearLocalStorage;
Vue.prototype.isValidEmail = isValidEmail;
Vue.prototype.checkMobile = checkMobile;
Vue.prototype.mySetCookie = mySetCookie;
Vue.prototype.checkPhone = checkPhone;



// console.log('API==',$APIurl)
Vue.prototype.$message = ElementUI.Message
Vue.use(ElementUI, {
  locale: enLocale

});

Vue.filter('LocaleString', function (value) {
  value = Number(value)
  var num = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // var num = Number(value).toLocaleString()
  num = num == 'NaN' ? 0 : num
  return num;
});

Vue.filter('formatDate_en', function (value) {

  var date = new Date(value);
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const dayIndex = date.getDay();
  const monthIndex = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();
  var time = `${dayNames[dayIndex]} ${monthNames[monthIndex]} ${day} ${year}`
  return time
});
// 2024
Vue.filter('formatDate_en_year', function (value) {

  var date = new Date(value);
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const dayIndex = date.getDay();
  const monthIndex = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();
  var time = `${year}`
  return time
});
// January
Vue.filter('formatDate_en_month', function (value) {

  var date = new Date(value);
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  // const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  const dayIndex = date.getDay();
  const monthIndex = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();
  var time = `${monthNames[monthIndex]}`
  return time
});
// 30
Vue.filter('formatDate_en_day', function (value) {

  var date = new Date(value);
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const dayIndex = date.getDay();
  const monthIndex = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();
  var time = day
  return time
});
// 30/05/2024
Vue.filter('formatDate', function (value) {
  var date = new Date(value);
  var month = date.getMonth() + 1;
  var day = date.getDate()
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var second = date.getSeconds();
  if (month < 10) {
    // console.log('month==', month)
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }


  if (hours < 10) {
    hours = "0" + hours;
  }

  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (second < 10) {
    second = "0" + second;
  }
  var time = day + '/' + month + '/' + date.getFullYear()
  return time;
});
// 02/30
Vue.filter('formatMonth', function (value) {
  var date = new Date(value);
  var month = date.getMonth() + 1;
  var day = date.getDate()
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var second = date.getSeconds();
  if (month < 10) {
    // console.log('month==', month)
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }


  if (hours < 10) {
    hours = "0" + hours;
  }

  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (second < 10) {
    second = "0" + second;
  }
  var time = month + '/' + day
  return time;
});

export const EventBus = new Vue();


// router.beforeEach((to, from, next) => {
//   console.log(to.path )
//   // 动态添加前缀
//   if (to.path !== '/') {
//     // 假设我们要添加的前缀是 '/prefix'
//     to.path = '/prefix' + to.path;
//   }
//   next(); // 必须调用next()来resolve这个钩子
// });

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')

