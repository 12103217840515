<template>
    <div class="page_d">

        <div class="container">
            <div class="container_title">
                {{ $t("message.SelectCountry") }}
            </div>
            <div class="row">
                <div class="country_li col-lg-3 col-sm-12 col-md-12 col-xs-12 col-ts-12 pointer"
                    v-for="item in countryList" :key="item" :label="item.countryName" :value="item"
                    @click="setcountry(item)">
                    <img :src="item.nationalFlagUrl" alt="img" class="country_img">
                    {{ item.countryName }}
                </div>
            </div>
            <!-- <div @click="goceshi()">ceshi</div> -->
        </div>
    </div>

</template>

<script>
import { EventBus } from "@/main"
import SvgIcon from "@/components/svgIcon/index.vue"
import "@/components/svgIcon/svg.js" // 批量导入svg文件
export default {

    components: {
        SvgIcon
    },
    name: "index",
    props: {
        msg: String,
    },
    data() {
        return {
            country: '',
            countryList: '',
        }
    },
    created() {
        
    },
    mounted() {
        console.log('11111')
        this.getdistrict_country_list()
    },
   
    beforeDestroy(){
        console.log('this.country=========',this.country)
        if(localStorage.getItem("country") && this.country.countryNameAbbreviation){
            if( this.country.countryNameAbbreviation != JSON.parse(localStorage.getItem("country")).countryNameAbbreviation){
                this.clearLocalStorage()
            }
        }
    },
    methods: {
        setcountry(item) {
            var that = this
            this.country = item
            if(item.activeStatus!= 1){
               
                that.$message.closeAll();
that.$message({
                    message:  that.$t("message.WarmingReminderYouCanOnlyAccessMalyssiaNow"),
                    customClass: 'custom-message',
                    duration: 2000,
                    offset: window.screen.height / 2
                });
                return false
            }
            if(localStorage.getItem("country") && item.countryNameAbbreviation != JSON.parse(localStorage.getItem("country")).countryNameAbbreviation){
                this.clearLocalStorage()
            }
            localStorage.setItem("country", JSON.stringify(item))
            var countryNameAbbreviation = JSON.parse(localStorage.getItem("country")).countryNameAbbreviation
            this.$nextTick(() => {
                
                this.$router.push({ name: 'Home', params: { myParam: countryNameAbbreviation } }, () => {
                    // location.reload();
                
            });
            })

        },
        goceshi(){
            this.$router.push({ name: 'ceshi'}, () => {
                    // location.reload();
                
            });
        },
        getdistrict_country_list() {
            var that = this
            that.$flyNo.request({
                method: 'GET',
                url: that.httpUrl.district_country_list,
                body: {
                }
            }).then(res => {
                if (!res) return false
                console.log('--------------——', that.httpUrl.district_country_list, '_res==', res)
                that.countryList = res.resultData.countryList
                that.$forceUpdate()

            })
        },
    }
}
</script>


<style lang="scss" scoped>
.country_li {
    margin-top: 33px;
    font-size: 16px;
    color: #000000;
}

.country_img {
    width: 46px;
    height: auto;
    margin-right: 19px;
}

.row {
    margin-bottom: 33px;
}
</style>