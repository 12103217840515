// i18n.js
import Module from '@/views/Module.vue';
import MyAccount from '@/views/MyAccount.vue';
import Rewards from '@/views/Rewards.vue';
import WhatsOn from '@/views/WhatsOn.vue';
import Video from '@/views/video.vue';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en: {
    message: {
      // 公共头部和底部
      Home: "Home",
      WhatSon: "What's on",
      Rewards: "Rewards",
      Module: "Module",
      TechWiki:"Auto Tech",
      MyAccount: "My account",
      BOSCHAZPortal: "Bosch Autoparts Customer Portal",
      YourApplicationIsPendingReview: "Your application is pending review",
      CorporateInformation: "Corporate information",
      LegalNotice: "Legal notice",
      DataProtectionPolicy: "Data protection policy",
      PrivacySettings: "Privacy settings",
      RobertBoschGmbH: "© 2021 - 2024 Robert Bosch GmbH, all rights reserved",

      // 选择国家页
      SelectCountry: "Select Country",
      WarmingReminderYouCanOnlyAccessMalyssiaNow: "You can only access Malyssia now. Other countries are coming soon! Please stay informed with us！",

      // 添加员工
      AddEmployee: "Add Employee",
      FillInEmployeeInfo: "Fill in employee info",
      SheHeIs: "She/He is",
      YourApplicationIsAentToYourTheOwnerOfYourCompanyForApproval:'Your application is sent to the owner of your company for approval .',


      // 表单
      Registration:"Registration",
      Step1:"Step 1",
      Step2:"Step 2",
      MobileNo:"Mobile No.",
      FirstName:"First name",
      LastName:"Last name",
      CompanyName:"Company Name",
      Email:"E-mail",
      EmailOptional:"Email (optional）",
      FullAddress:"Full address",
      Address:"Address",
      City:"City",
      PleaseEnter:"Please enter",
      ProvinceState:"Province / State",
      ZipCode:"Zip Code",
      Continue:"Continue",
      Step3:"Step 3",
      IAmA:"I am a",
      IAm:"I am",
      RepairSegment:"Repair Segment",
      VehicleSegment:"Vehicle Segment",
      PreferredDistributor:"Preferred Distributor",
      Workshop:"Workshop",
      Retailer:"Retailer",
      TradeLevel:"Trade level",
      PortalId:"Portal id",
      Distributor:"Distributor",
      Diesel:"Diesel",
      Gasoline:"Gasoline",
      OthersEV:"Others (EV)",
      Motorcycle:"Motorcycle",
      PassengerCars:"Passenger Cars",
      TrucksBus:"Trucks/Bus",
      NoOfWorkbay:"No. of workbay",
      FillIn:"Fill in",
      Namecard:"Namecard",
      TakeNamecard:"Take Namecard",
      CompanyShopfront:"Company Shopfront",
      PleaseUploadYourCompanyNamecard:"Please upload your Company Namecard",
      TakePhotoOfCompanyShopfront:"Take photo of Company Shopfront",
      PleaseUploadYourCompanyShopfront:"Please upload your Company Shopfront",
      SignUpForEXtraProgram:"Sign up for eXtra program",
      PleaseCheckAgain:"Please Check Again",
      IsTheGivenInformationCorrect:"Is the given information correct",
      DoYouAgreeOurPrivacyPolicy:"Do you agree our privacy policy",
      DoYouAgreeOurTermsConditions:"Do you agree our terms&Conditions",
      ConfirmToRegister:"Confirm to register",
      PleaseCheckIfTBD:"Please check if the given information is correct?（TBD)",
      Back:"Back",
      Confirm:"Confirm",
      PleaseCheckIfTheGivenInformationIsCorrect:"Please check if the given information is correct?",
      PleaseEnterTheRequiredInfo:"Please enter the required info",
      pleaseMakeSureIfYouWannaSignUpForEXtraProgram:"please make sure if you wanna sign up for eXtra program",
      AlreadySubmittedPendingApproval:"Already submitted , pending approval",
      OwnerMobileNo:"Boss Mobile No.",
      Manager:"Manager",
      Employee:"Employee",
      Owner:"Boss",
      RequestANewOTP:"Request a new OTP",
      YouHaveSelectdSMSAsNotificationChannel:"You have selectd SMS as notification channel, you will shortly receive an OTP to the mobile number you provided. please enter the OTP to complete your registration",
      DataProtectionGuidelines:"Data Protection Guidelines",
      GeneralTermsConditions:"General Terms and Conditions",
      PleaseEnterYourPhoneNumber:"Please enter your phone number",
      CellPhoneNumberIsRequired:"Cell phone number is required",
      pleaseAgreeOurTermsAndConditions:"please agree our terms and conditions.",
      pleaseAgreeOurDataProtectionPolicy:"please agree our Data protection policy.",
      pleaseAgreeOurLegalNotice:"please agree our Legal notice.",
      PleaseChooseYourRegion:"Please choose your region",
      PleaseEnterOTP:"Please enter OTP",
      LoginToProceed:"Login to Proceed",
      Login:"Login",
      RegisterNow:"Register Now",
      DontHaveAnAccount:"Don’t have an account",
      OTP:"OTP",
      RequestANewOTP:"Request a new OTP",
      YourApplicationIsPendingApproval:"Your application is pending approval, please wait a second!",
      success:"success！",
      rule_list:"History Version",
      AccountData:"Account Data",
      OwnersFirstName:"Boss's First Name",
      OwnersLastName:"Boss's Last Name",
      CountryRegion:"Country / Region",
      Role:"Role",
      ModifyBasicInformation:"Modify basic information",
      PleaseFillInYourEmail:"Please fill in your Email",
      CompanyData:"Company data",
      eXtraID:"eXtra ID",
      ModifyInformation:"Modify information",
      ModifyKeyInformation:"Modify key information",
      ChangeCompanyPhoto:"Change company photo",
      LogOut:"Log out",
      NoDataAvailable:"No data available",
      Search:"Search",
      Modify:"Modify",
      PleaseCheckTheDataProtectionPolicyAndLegalNotice :"Please check data protection policy and legal notice!",

      // 人员管理
      RoleManagement:"Role management",
      Time:"Time",
      Refuse:"Refuse",
      Accept:"Accept",
      DoYouConfirmToApproveIt:"Do you confirm to approve it?",
      DoYouConfirmToRejectIt:"Do you confirm to reject it?",
      PleaseConfirmAgain:"Notification",
      RoleInformation:"Role information",
      Name:"Name",
      Activate:"Activate",
      Disable:"Disable",
      IdentitySettings:"Identity settings",
      DoYouConfirmToChangeThisInformation:"Do you confirm to change this information?",
      DoYouConfirmToActivateThisAccount:"Do you confirm to activate this account？",
      DoYouConfirmToDisableThisAccount:"Do you confirm to disable this account?",

      // article
      PurchaseRecord:"Purchase record",
      TotalPurchase:"Total purchase",
      valueSGD:"value (SGD)",
      TotalPurchaseValue:"Total purchase value",
      TotalPurchaseAmt:"Total purchase amt",
      ProcurementRecords:"Procurement records",
      View:"View",
      LatestArticles:"Latest Articles",
      LearnMore:"Learn More >",

      // Autoparts
      AutoParts:"Auto Parts",
      ViewMore:"View more >",
      BoschGroup:"Bosch Group",
      BoschMobilityAftermarket:"Bosch Mobility Aftermarket",

      // BSSList
      WelcomeBoschModulePartner:"Welcome Bosch Module Partner!",
      MyBenefits:"My benefits >",
      MoreInfoAboutModule:"More info. about Module >",
      AnnualPartsTarget:"Annual parts target",
      StillToGo:"Still-to-go",
      ContractTarget:"Contract target",
      TotalAchievement:"Total achievement",
      Year:"Year",
      ActualSales:"Actual sales",
      ContractPeriod:"Contract Period",
      years:"years",
      JoinDate:"Join date",
      Marketing:"Marketing",
      promotionZone:"promotion zone",
      Attended:"Attended",
      Done:"Done",
      Received:"Received",
      Abandoned:"Abandoned",
      to:"to",
      CurrentPurchase:"Current purchase",
      SGD:"SGD",
      MarketingPromotionZone:"Marketing promotion zone",
      NotAttended:"Not Available",

      // contact_us
      Like:"Like",
      Comment:"Comment",
      SaySometihing:"Say sometihing",
      Sending:"Send",
      User:"User",
      PleaseTypeWords:"Please type words",
      AutomotiveTech:"Automotive Tech",
      views:"views",

      // Home
      FunctionalityInDevelopment:"Functionality in development",

      // Module
      Apply:"Apply",
      JoinUs:"Join us",
      BoschModuleConcept:"Bosch module concept",
      IHaveReadAndAcceptedTheBoschModulePolicy:"I have read and accepted the Bosch module policy.",
      SignNow:"Sign Now",
      PleaseFillInTheBelowArea:"Please fill in the below area",
      PleaseFillInYourFirstNameUnrecognizedOrIncorrectSignatureWillLeadToRegistrationFailure:" Please fill in your First name. Unrecognized or incorrect signature will lead to registration failure",
      PlaeaseFillInYourFirstName:"Plaease fill in your first name",
      pleaseCheckTheBoschModulePolicy:"please check the Bosch module policy",
      KindlyProvideSignatureOrFullName:"Kindly provide signature or full name",

      // MyAccount
      loggedInSuccessfully:"logged in successfully!",
      IAlreadyHaveAnOTP:"I already have an OTP",
      Tel:"Tel",
      PendingIdentityForReview:"Pending identity for review",
      EnterBoschModuleTiering:"Enter Bosch Module tiering >",
      CurrentMember:"Current member",
      EarnPointsEveryDay:"Earn points every day",
      eXtraMall:"eXtra Mall",
      eXtraPoints:"eXtra points",
      eXtraPoint:"eXtraPoint",
      Go:"Go",
      Redeem:"Redeem",
      MyServices:"My Services",
      eXtra:"eXtra",
      Order:"Order",
      Points:"Points",
      Details:"Details",
      Personal:"Personal",
      Data:"Data",
      Privacy:"Privacy",
      Clause:"Clause",
      PersonalData:"Personal data",
      eXtraWokrshopCandidate:"Membership pending",
      KevinCarRepairServiceCenter:"Kevin Car repair service center",
      MoreInfoAboutCompany:"More info about Company",
      MoreInfoAboutYou:"More info about you",
      MyFirstName:"My first name",
      MyLastName:"My last name",
      BossMobileNumber:"Boss mobile number",

      // Recordltem
      time:"time",
      item:"item",
      Qty:"Qty",
      PN:"PN",
      dealer:"dealer",
      Download:"Download",

      // Rewards
      expiresOn:"expires on",
      ActivityRules:"Activity rules",
      CheckInDone:"Check-in done",
      CheckInNOW:"Check-in NOW",

      // Video
      FoundTheQRCode:"Found the QR Code",
      PleaseUseYourMobilePhoneToLog:"Please use your mobile phone to log on so as to scan product successfully!",
      PleaseAllowAuthorizedUseOfYourPhone:"Please allow authorized use of your phone camera and scan again!",

      // WhatsOn
      ThoEvontTeasorGroupOllors:"Tho evont teasor group ollors tho possibility to prominently displny several evonts within one compent.",
    }
    // 未接：
    // 签到页扫码得奖英文拼接
    // that.$t("message.PleaseAllowAuthorizedUseOfYourPhone") BSSList
    // {{ $t("message.ThoEvontTeasorGroupOllors") }}
  },
  zh: {
    message: {
      hello: '你好世界'
    }
  }
};

const i18n = new VueI18n({
  locale: 'en', // 默认语言
  messages,
});

export default i18n;