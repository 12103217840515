// import { fly } from './request'
let api = {

  //接口表
  policyhistorylist: '/privacy/policy/history/list',    //隐私&条款--历史版本列表
  policyinfotype: '/privacy/policy/info/type',      //隐私&条款--根据分类获取详情
  policyinfo: '/privacy/policy/info',    //隐私&条款--根据主键ID获取详情

  district_country_list:"/district/country/list", //国家列表
  district_province_list: '/district/province/list',    //省&州列表
  authlogin: '/auth/login',    //登录接口	
  registersubmit: '/register/submit',    //注册新维修站
  register_worker_submit: '/register/worker/submit',    //注册员工
  query_clientList: '/public/query/clientList',    //查询维修站
  register_querySpiRegistryApp: '/register/querySpiRegistryApp',    //经销商列表
  file_upload: '/public/file/upload',    //经销商列表
  register_sendAliSms: '/register/sendSms',    //发送阿里云短信
  register_verifySMSCode: '/register/verifySMSCode',    //校验短信验证码
  client_info: '/client/info',    //客户信息
  client_info_modifyRepairStationPhoto: '/client/activation/modifyRepairStationPhoto',    //修改维修站照片
  client_info_modifyingBasicInformation: '/client/activation/modifyingBasicInformation',    //修改基本信息
  client_info_bossAddedEmployee: '/client/user/bossAddedEmployee',    //老板添加新员工
  queryBaseClientInfo: '/register/queryBaseClientInfo',    //查询是否导入的维修站信息

  


  register_check: '/register/check',    //检查注册信息是否重复

  

  home_banner : '/device/home/banner', //轮播图/导航栏
  activity_scan : '/activity/2022-scan/scan', //扫码入参


  client_userapply_allList: '/client/user/apply/allList',    // 获取申请员工列表
  client_userapply_verify: '/client/user/apply-verify',    // 老板审批员工


  client_manageEmployeeDisableEnabled: '/client/user/manageEmployeeDisableEnabled',// 管理员工：禁用 启用
  client_updateEmployeeUserClassify: '/client/user/updateEmployeeUserClassify',// 管理员工：修改员工分类
  client_verifyWhetherAuditIsSuccessful: '/client/query/verifyWhetherAuditIsSuccessful',// 校验维修站审核状态

 

  technicalencyclopediapage:'/technicalencyclopedia/page', //技术百科分页查询
  technicalencyclopedia_technicalEncyclopediaTypeList:'/technicalencyclopedia/technicalEncyclopediaTypeList', //技术百科分类列表查询
  technicalencyclopedia_updateEncyclopediaPointsReadNum: '/technicalencyclopedia/updateEncyclopediaPointsReadNum', //技术百科--更新阅读量--观看视频时
  technicalencyclopedia_detaile:"/technicalencyclopedia/detaile", //通过id查询技术百科
  technicalencyclopedialike_add:"/technicalencyclopedialike/add", //新增点赞
  technicalencyclopedialike_delete:"/technicalencyclopedialike/delete", //通过id删除技术百科--点赞
  technicalencyclopediacomment_page:'/technicalencyclopediacomment/page', //评论分页查询
  technicalencyclopediacomment_add:'/technicalencyclopediacomment/add', //新增${comments}
  technicalencyclopediacomment_language_type:'/technicalencyclopedia/technicalEncyclopediaLanguageTypeList', //语言列表
  product_type_list:'/technicalencyclopedia/product/type/list', //产品线

 

  questionInformationpage:'/questionInformation/page', //问答资讯分页查询
  questionInformationpage_detaile:"/questionInformation/detaile", //问答资讯--通过id查询
  questionInformationpage_new:"/questionInformation/getLatestInformation", //查询最新问答资讯

  signed_verifySigned:"/signed/verifySigned", //签约校验 查询签约记录,判断用户是否已经签约(bss_contract_info 的 contract_status) 0 未签约 1.已经签约
  signed_bssImmediateSigned:"/signed/bssImmediateSigned", //立即签约 校验用户是否为修理厂渠道用户,以及其他条件是否符合
  bsss_activity_purchaseRecord:"/bss/activity/purchaseRecord", //采购进度
  bsss_activity_list:"/bss/activity/list", //活动列表
  bsss_activity_getGift:"/bss/activity/getGift", //领取活动奖励
  bsss_activity_refuseGift:"/bss/activity/refuseGift", //放弃活动奖励
  bsss_activity_orderSum:"/bss/activity/orderSum", //采购总金额、总件数
  bsss_activity_orderList:"/bss/activity/orderList", //采购订单列表
  bsss_activity_orderDetailList:"/bss/activity/orderDetailList", //采购订单明细列表
  bsss_activity_orderDetailList_export:"/bss/activity/orderDetailList/export", //导出采购明细
  bsss_activity_orderAnalysis:"/bss/activity/orderAnalysis", //采购明细图表
  bsss_activity_productType:"/bss/activity/productType", //产品类型的下拉选项
  
  
  client_isSign:"/client/isSign", //签到状态
  client_sign:"/client/sign", //签到


  client_query_client_List:"/client/query/client-List", //维修厂列表
  autoPartsInfo_List:"/autoPartsInfo/list", //汽车零部件信息
  


  



}

export default {
  api,
}