// common.js


// 清空缓存
export function clearLocalStorage() {
    console.log('清空缓存')
    localStorage.removeItem('client_info');
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        console.log('key==', key)
        if (key != 'tab' && key != 'host' && key != 'country') {
            localStorage.removeItem(key);
        }
    }
    console.log('clearLocalStorage_succ')
}
// 缓存登录后2小时时间戳
export function mySetCookie() {
    //获取当前时间
    var nowTime = new Date();//获取当前时间对象的时间戳
    var t = nowTime.getTime() + (24 * 60 * 60 * 1000)//增加24小时的时间差
    // var t = nowTime.getTime() + (2 * 60 * 1000)//测试增加5分钟的时间差
    console.log('t=====', t)
    console.log('nowTime=====', nowTime.getTime())
    localStorage.setItem("lognTime", t)
}
// 判断Pc移动
export function checkMobile() {
    const width = window.innerWidth || document.documentElement.clientWidth;
    var isMobile = width <= 1200; // 可以根据需要调整这个阈值
    return isMobile
}
// 校验邮箱
export function isValidEmail(email) {
    // 简单的正则表达式来验证邮箱格式
    // const emailRegex = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);

}
// 校验手机号 6~14位数字验证
export function checkPhone(phone) {


    if (phone == "") {

        return false;

    }

    if (phone != "") {

        var regex  = /^\d{6,14}$/;

        var me = false;

        if (regex .test(phone)) me = true;

        if (!me) {

            //alert('对不起，www.phpstudy.net您输入的电话号码有错误。区号和电话号码之间请用-分割');

            return false;

        }

    }

    return true;

}
//   2024-02-03
export function formatDate(value) {
    var date = new Date(value);
    var month = date.getMonth() + 1;
    var day = date.getDate()
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var second = date.getSeconds();
    if (month < 10) {
        // console.log('month==', month)
        month = "0" + month;
    }
    if (day < 10) {
        day = "0" + day;
    }


    if (hours < 10) {
        hours = "0" + hours;
    }

    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (second < 10) {
        second = "0" + second;
    }
    var time = date.getFullYear() + "-" + month + "-" + day
    return time;
}
//  Thursday April 29 2021
export function formatDate2(value) {
    var date = new Date(value);
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const dayIndex = date.getDay();
    const monthIndex = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    var time = `${dayNames[dayIndex]} ${monthNames[monthIndex]} ${day} ${year}`
    return time
}