<template>
    <div class="header ">
        <!-- <div class="pcShow" :style="{ 'height': offsetHeight + 'px' }"></div> -->
        <div class="pcShow" style="height: 141px"></div>
        <div class="ydShow" style="height: 67px "></div>
        <header class="header style7 pcShow header_top" ref="elementTop" style="border-bottom: 1px solid #D9D9D9;">
             <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon17.png" alt="img" class="icon17 ">
            <!--<img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon17.gif" alt="img" class="icon17 "> -->
            <div class="container ydShow_padding0">
                <!-- <div class="main-header d-flex " :class="scroll == 1 ? 'scrollTop' : scroll == 2 ? 'scrollbom' : 'headerH'"> -->
                <div class="main-header d-flex flex-column headerH">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class=" header-element pointer ydShow_padding0" @click="gohome(); switch_nav = 1;">
                            <div class="logo">
                                <svg class="icon-logo">
                                    <use xlink:href="#icon-logo"></use>
                                </svg>
                            </div>
                        </div>

                        <div class=" ydShow_padding0" v-if="country && pathName != 'index'">
                            <div class="header-control pointer">
                                <div
                                    class="block-minicart dungar-mini-cart block-header dungar-dropdown d-flex align-items-center">
                                    <!-- <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon1.png" alt="img"
                                        class="shopcart-icon"> -->
                                    <svg @click="goindex()" class="shopcart-icon">
                                        <use xlink:href="#icon-icon3"></use>
                                    </svg>
                                    <div style="margin-left: -5px;" @click="goindex()">{{
                            country.countryNameAbbreviation }}</div>
                                    <svg class="shopcart-icon" @click="goMyAccount()">
                                        <use xlink:href="#icon-icon1"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div v-else class="col-lg-9 country_no">{{ $t("message.BOSCHAZPortal") }}</div>
                    </div>
                    <div class="row ydShow_padding0" v-if="country && pathName != 'index'">
                        <div class="d-flex justify-content-between align-items-center">

                            <ul class="nav-d">
                                <div class="nav-item pointer" @click="gohome(); switch_nav = 1;">
                                    <div :class="nav == 1 ? 'active' : ''">{{ $t("message.Home") }}</div>

                                </div>
                                <div class="nav-item pointer" @click="goWhatsOn(); switch_nav = 1;">
                                    <div :class="nav == 2 ? 'active' : ''">{{ $t("message.WhatSon") }}</div>

                                </div>
                                <div class="nav-item pointer" @click="gocontact_us(); switch_nav = 1;">
                                    <div :class="nav == 3 ? 'active' : ''">{{ $t("message.TechWiki") }}</div>
                                </div>
                                <div class="nav-item pointer" @click="goModule(); switch_nav = 1;">
                                    <div :class="nav == 4 ? 'active' : ''">{{ $t("message.Module") }}</div>

                                </div>
                                <div class="nav-item pointer" @click="goMyAccount(); switch_nav = 1;">
                                    <div :class="nav == 5 ? 'active' : ''">{{ $t("message.MyAccount") }}</div>
                                </div>
                            </ul>
                            <div class="AutoParts">Bosch Autoparts Customer Portal</div>
                        </div>

                    </div>
                </div>
            </div>
        </header>
        <div class="header-device-mobile ydShow header_top" ref="elementTop2"
            :class="switch_nav == 2 ? 'switch_nav_d' : ''">
            <!-- <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon16.png" alt="img" class="icon16"> -->
            <img src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon16.gif" alt="img" class="icon16">
            <div class="wapper">
                <div class="item mobile-logo pointer" @click="gohome(); switch_nav = 1;">
                    <div class="logo">
                        <svg class="icon-logo">
                            <use xlink:href="#icon-logo"></use>
                        </svg>
                    </div>
                </div>
                <div class="flex1"></div>
                <div class="item" v-if="country && pathName != 'index'">
                    <svg class="menu-toggle icon2" @click="goindex()">
                        <use xlink:href="#icon-icon3"></use>
                    </svg>
                    <div style="margin-left: 0px;" @click="goindex()">{{ country.countryNameAbbreviation }}</div>
                    <svg class="menu-toggle icon2" @click="goMyAccount()">
                        <use xlink:href="#icon-icon1"></use>
                    </svg>
                    <svg class="menu-toggle icon2 pointer" v-if="switch_nav == 1" @click="switch_nav = 2">
                        <use xlink:href="#icon-icon2"></use>
                    </svg>
                    <svg v-if="switch_nav == 2" @click="switch_nav = 1"
                        class="menu-toggle icon2 animate__animated animate__rotateInDownLeft pointer">
                        <use xlink:href="#icon-icon4"></use>
                    </svg>

                </div>
                <div v-else class="country_no">{{ $t("message.BOSCHAZPortal") }}</div>
            </div>
            <div class="switch_nav_bg animate__animated animate__fadeIn" v-if="switch_nav == 2">
                <div class="switch_li d-flex align-items-center justify-content-between pointer"
                    @click="gohome(); switch_nav = 1;">
                    <div>{{ $t("message.Home") }}</div>
                    <img class="menu-toggle icon21"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img">
                </div>
                <div class="switch_li d-flex align-items-center justify-content-between pointer"
                    @click="goWhatsOn(); switch_nav = 1;">
                    <div>{{ $t("message.WhatSon") }}</div>
                    <img class="menu-toggle icon21"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img">
                </div>
                <div class="switch_li d-flex align-items-center justify-content-between pointer"
                    @click="gocontact_us(); switch_nav = 1;">
                    <div>{{ $t("message.TechWiki") }}</div>
                    <img class="menu-toggle icon21"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img">
                </div>
                <div class="switch_li d-flex align-items-center justify-content-between pointer"
                    @click="goModule(); switch_nav = 1;">
                    <div>{{ $t("message.Module") }}</div>
                    <img class="menu-toggle icon21"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img">
                </div>
                <div class="switch_li d-flex align-items-center justify-content-between pointer"
                    @click="goMyAccount(); switch_nav = 1;">
                    <div>{{ $t("message.MyAccount") }}</div>
                    <img class="menu-toggle icon21"
                        src="https://boshi2023.oss-cn-shanghai.aliyuncs.com/H5_img/icon21.png" alt="img">
                </div>
            </div>


        </div>
    </div>
</template>

<script>

import SvgIcon from "@/components/svgIcon/index.vue"
import "@/components/svgIcon/svg.js" // 批量导入svg文件
import { EventBus } from "../main"
export default {
    components: {
        SvgIcon
    },
    name: 'Header',
    props: {
        msg: String
    },
    data() {
        return {
            country: '',
            nav: 1,
            switch_nav: 1,
            clientInfo: '',
            scroll: -1,
            scrollTop: '',
            offsetHeight: '',
            pathName: '',
        }
    },
    watch: {
        $route(to, from, next) {
            var that = this
            that.scroll = -1
            const currentRoute = that.$router.currentRoute;
            that.pathName = currentRoute.name
            setTimeout(function () {
                // that.offsetHeight = that.$refs.elementTop.offsetHeight || that.$refs.elementTop2.offsetHeight
                // console.log('offsetHeight____________', that.offsetHeight)
                // that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                that.$nextTick(() => {
                    that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                })
            }, 800)




        },
    },
    created() {
        var that = this
        EventBus.$on('nav', (newValue) => {
            // 处理全局变量变化
            // console.log('nav=====', newValue);
            that.country = JSON.parse(localStorage.getItem("country")) || ''
            // console.log('that.country ====================', that.country)
            that.nav = newValue.nav
            localStorage.setItem("nav", that.nav)
            setTimeout(function () {
                console.log('2222222222222222222222222222222222222222')
                that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
            }, 200)

        })
        EventBus.$on('scrollTop', (newValue) => {
            that.scrollTop = newValue.scrollTop
            if (that.scrollTop >= 20) {
                if (that.scroll == 2 || that.scroll == -1) {
                    that.offsetHeight = 103
                    that.scroll = 1
                }

            } else {
                if (that.scroll == 1 || that.scroll == -1) {
                    that.offsetHeight = 163
                    that.scroll = 2
                }
            }
            // that.offsetHeight = that.$refs.elementTop.offsetHeight
        })
    },
    mounted() {
        var that = this
        that.country = JSON.parse(localStorage.getItem("country")) || ''
        // console.log('elementTop==', this.$refs.elementTop.offsetHeight)
        this.offsetHeight = this.$refs.elementTop.offsetHeight

        const currentRoute = this.$router.currentRoute;
        this.pathName = currentRoute.name
        // console.log('this.pathName===', this.pathName)
    },
    beforeDestroy() {
        EventBus.$off('nav')
    },
    methods: {
        goindex() {
            if (!localStorage.getItem("country")) return
            this.switch_nav = 1
            this.$router.push({
                name: "index"
            }, () => {
                this.$nextTick(() => {
                    this.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                })
            })
        },
        setNav() {
            var that = this
            this.country = localStorage.getItem("country") || ''
            if (localStorage.getItem("client_info")) {
                this.clientInfo = JSON.parse(localStorage.getItem("client_info"))
                // console.log('clientInfo==', this.clientInfo)
            }
            const currentRoute = this.$router.currentRoute;
            let path = currentRoute.path
            this.pathName = currentRoute.name
            // console.log('path========', currentRoute.path);
            if (path == '/Home') {
                this.nav = 1
            } else if (path == '/WhatsOn') {
                this.nav = 2
            } else if (path == '/Rewards') {
                this.nav = 3
            } else if (path == '/Module') {
                this.nav = 4
            } else if (path == '/MyAccount') {
                this.nav = 5
            }
        },
        gopersonalData_index() {
            var that = this
            this.switch_nav = 1
            if (localStorage.getItem("client_info")) {
                that.$router.push({
                    name: "personalData_index"
                }, () => {
                    that.$nextTick(() => {
                        that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                    })
                })
            } else {
                that.$router.push({
                    name: "login_index",
                }, () => {
                    that.$nextTick(() => {
                        that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                    })
                });
            }
        },
        gohome() {
            var that = this
            this.nav = 1
            var countryNameAbbreviation = JSON.parse(localStorage.getItem("country")).countryNameAbbreviation
            this.$router.push({ name: 'Home', params: { myParam: countryNameAbbreviation } }, () => {
                that.$nextTick(() => {
                    that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                })
            });
        },
        goWhatsOn() {

            var that = this
            this.nav = 2
            that.$router.push({
                name: "WhatsOn"
            }, () => {
                that.$nextTick(() => {
                    that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                })
            })
        },

        goRewards() {
            var that = this
            this.nav = 3
            that.$router.push({
                name: "Rewards"
            }, () => {
                that.$nextTick(() => {
                    that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                })
            })
        },
        gocontact_us() {
            var that = this
            this.nav = 3
            that.$router.push({
                name: "contact_us"
            }, () => {
                that.$nextTick(() => {
                    that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                })
            })
        },
        goModule() {
            var that = this
            this.nav = 4

            if(!localStorage.getItem("client_info")){
                that.$router.push({
                        name: "Module"
                    }, () => {
                        // that.$nextTick(() => {
                        //     that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                        // })
                    })
                    return false
            }
            that.$flyNo.request({
                method: 'GET',
                url: that.httpUrl.signed_verifySigned,
                body: {
                }
            }).then(res => {
                if (!res) return false
                console.log('--------------——', that.httpUrl.signed_verifySigned, '_res==', res)
                // 0未申请，1审核中，2审核通过
                localStorage.setItem("signed_verifySigned_contractStatus", res.resultData.contractStatus)

                // 0未申请，1审核中，2审核通过
                if (localStorage.getItem("signed_verifySigned_contractStatus") == 0 || !localStorage.getItem("clientToken")) {
                    that.$router.push({
                        name: "Module"
                    }, () => {
                        that.$nextTick(() => {
                            that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                        })
                    })
                    return false
                } else if (localStorage.getItem("signed_verifySigned_contractStatus") == 1) {
                    that.$router.push({
                        name: "BSSList"
                    }, () => {
                        that.$nextTick(() => {
                            that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                        })
                    })
                } else if (localStorage.getItem("signed_verifySigned_contractStatus") == 2) {
                    that.$router.push({
                        name: "BSSList"
                    }, () => {
                        that.$nextTick(() => {
                            that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                        })
                    })
                } else {
                    that.$router.push({
                        name: "Module"
                    }, () => {
                        // that.$nextTick(() => {
                        //     that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                        // })
                    })
                }


            })





        },
        goMyAccount() {
            // console.log('2222')
            var that = this
            if (localStorage.getItem("client_info")) {
                that.nav = 5
                that.$router.push({
                    name: "MyAccount",
                }, () => {
                    that.$nextTick(() => {
                        that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                    })
                })
            } else {
                that.$router.push({
                    name: "login_index",
                }, () => {
                    that.$nextTick(() => {
                        that.$refs.elementTop.scrollIntoView({ behavior: 'smooth' });
                    })
                });
            }

        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header-control {
    position: relative;
    left: 6px;
}

.pcShow .nav-d {
    padding-top: 10px;
    margin-left: -20px;
}

.AutoParts {
    font-weight: bolder;
    align-self: flex-end;
    padding-bottom: 10px;
    color: #000;
}

.nav-item {
    flex: initial;

}

.nav-item div {
    padding: 10px 20px;
}

.nav-item:hover {
    color: #007BBF;

}

.headerH {
    padding-top: 20px;
    // padding: 20px 0;
}

.scrollTop {
    animation-name: paddingChange;
    animation-duration: 0.5s;
    /* 动画持续时间 */
    animation-fill-mode: forwards;
    /* 动画结束后保持最后一帧的样式 */
    animation-timing-function: ease-out;
    padding: 50px 0;
}

@keyframes paddingChange {
    from {
        padding: 50px 0;
    }

    to {
        padding: 20px 0;
    }
}

.scrollbom {
    animation-name: paddingChange2;
    animation-duration: 0.1s;
    /* 动画持续时间 */
    animation-fill-mode: forwards;
    /* 动画结束后保持最后一帧的样式 */
    animation-timing-function: ease;
    padding: 20px 0;
}

@keyframes paddingChange2 {
    from {
        padding: 20px 0;
    }

    to {
        padding: 50px 0;
    }
}

.header_top {
    position: fixed;
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

.icon {
    width: 1em;
    height: 1em;
    fill: currentColor;
    vertical-align: -0.15em;
}

.svg-icon {
    width: 1em;
    height: 1em;
    fill: currentColor;
    vertical-align: -0.15em;
}

.mobile-logo {
    width: 118px;
    height: auto;
}

.country_no {
    color: #000;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;
}

.header {
    background: #fff;
}

.switch_nav_d {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #fff;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    pointer-events: auto;
}

.switch_nav_bg {
    padding-top: 10px;
    width: 100%;
    flex: 1;
    background: #fff;

}

.switch_li {
    height: 66px;
    padding: 0 18px;
    box-sizing: border-box;
    border-bottom: 1px solid #F2F2F2;

}

.icon21 {
    width: 7px;
    height: auto;
}
</style>
