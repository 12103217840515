var Fly = require("flyio/dist/npm/fly")
import qs from 'qs';
import Vue from 'vue'
import { mySetCookie } from '@/static/js/common.js'
var fly = new Fly;
import { Message, Loading } from 'element-ui'
import $router from "../router";

let host 
//'https://extraen.hug66.com/bosch-enh5-api/api'; //本地测试
//'http://q.ap-portal.boschaftermarket.com/bosch-enh5-api/api'; //博世测试

let locationUrl = window.location.host
console.log('locationUrl==', locationUrl)
if(locationUrl == 'ap-portal.boschaftermarket.com'){
    console.log('正式环境')
    host = 'http://ap-portal.boschaftermarket.com/bosch-enh5-api/api'; //正式环境
}else if(locationUrl == 'q.ap-portal.boschaftermarket.com'){
    console.log('博世测试')
    host = 'http://q.ap-portal.boschaftermarket.com/bosch-enh5-api/api'; //博世测试
}else{
    console.log('本地测试')
    host = 'https://extraen.hug66.com/bosch-enh5-api/api'; //本地测试 
    // host = 'http://q.ap-portal.boschaftermarket.com/bosch-enh5-api/api';  //博世测试 
}
localStorage.setItem('host', host)
var MessageLayer

fly.interceptors.request.use((request) => {
    var t  = new Date().getTime()
    if(localStorage.getItem("clientToken")){
        if(localStorage.getItem("lognTime") && t  > localStorage.getItem("lognTime")){
            console.log('过期了')
            if ($router.name != '/login_index') {
                $router.push({
                    name: "login_index"
                }, () => { })
            }
            return false
        }else{
            mySetCookie()
            console.log('未过期更新时间')
          
        }
    }
    



    var clientInfo = localStorage.getItem("client_info") ? JSON.parse(localStorage.getItem("client_info")) : ''
    var clientToken = localStorage.getItem("clientToken") || ''
    var country = localStorage.getItem("country") ? JSON.parse(localStorage.getItem("country")) : ''
    MessageLayer = Loading.service({ text: "Loading...", spinner: "el-icon-loading" })


    setTimeout(function () {
        MessageLayer.close()
        
    }, 1000)

    request.headers = {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': clientToken ? 'Bearer ' + localStorage.getItem('clientToken') : '',
        'Countrynameabbreviation':country.countryNameAbbreviation || ''
    }
    request.body['userId'] = clientInfo?.clientUserId || clientInfo?.clientUserDTO?.clientUserId || ''
    // console.log('request.body==', request.body)
    // request.body && Object.keys(request.body).forEach((val) => {
    //     if (request.body[val] === '') {
    //         delete request.body[val]
    //     }
    // })
    return request
})
fly.interceptors.response.use(
    (response) => {
        MessageLayer.close()
        console.log('succ====', response, response.data.success)
       
        if(response.request.url == '/bss/activity/orderDetailList/export'){
            return response.data
        }
        if (!response.data.success) {
            console.log('接口失败', response.data.resultMsg)
            Message.closeAll();
            Message({
                message: response.data.resultMsg,
                customClass: 'custom-message',
                duration: 5000,
                offset: window.screen.height / 2
            });
            return false

        } else {
            return response.data
        }

    },
    (err) => {
        MessageLayer.close()
        console.log('err====', err)
        console.log('$router.name==', $router.name)
        if (err.status == '401') {
            // Message("Sorry, we haven't got your account information, please log in again.")
            // “抱歉！您的身份已失效。请重新注册或绑定维修厂，如有疑问请联系客服处理。”
          
            if ($router.name != '/login_index') {
                $router.push({
                    name: "login_index"
                }, () => { })
            }
            return false
        } else {
            Message.closeAll()
            Message({
                message: err.message|| err.data.resultMsg,
                customClass: 'custom-message',
                duration: 5000,
                offset: window.screen.height / 2
            });
        }
        if (err) {
            return '请求失败'
        }
    }
)



fly.config.baseURL = host



// export const get = (params) => {
//     return fly.get(`${params.url}`, qs.stringify({ ...params.data }))
// }
// export const post = (params) => {
//     return fly.get(`${params.url}`, qs.stringify({ ...params.data }))
// }
export default fly;
export {
    fly,

}